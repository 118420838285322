<template>
  <section class="main-section">
     
  </section>
</template>

<script>
export default {
  data() {
    return {
      activity: null,
      filters: {
        group: null
      }
    }
  },
  mounted() {
    this.populateActivity();
  },
  methods: {
    async populateActivity() {
      const resp = await this.$api.Reporting.get_recognition(this.filters)
      this.activity = resp;
    }
  }
}
</script>